import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { User } from '../../_models';
import { UserService, AuthenticationService } from '../../_services';
import {ApiService} from '../../_services/api.service';
import { environment } from 'src/environments/environment';


@Component({ templateUrl: 'home.component.html', styleUrls: ['./home.component.css'] })
export class HomeComponent implements OnInit {
    currentUser: User;
    users = [];
    stats: any;
    backups: string[];
    backupsParsed: {filename: string, ext: string, type: string, datetime: string}[] = [];
    loading: boolean = true;
    creatingBackup = false;

    constructor(
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private API: ApiService
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.loading = true;
    }

    ngOnInit() {
        this.init();
    }
    async init() {
        this.loading = true;

        this.stats = await this.API.getStats();
        this.backups = await this.API.getBackupList();
        this.backupsParsed = [];

        for (const b of this.backups) {
            if (!b.startsWith('osesecurity')) {
              this.creatingBackup = true;
              continue;
            }
            const ext = b.endsWith('.sql') ? 'sql' : 'zip';
            const parsed = b.split('_');
            let datetime =  parsed[2].slice(0, -4).replace(/T(\d{2})-(\d{2})-(\d{2})-(\d{3})Z/, 'T$1:$2:$3.$4Z');
            datetime = new Date(datetime).toLocaleString();

            this.backupsParsed.push({
                filename: b,
                ext,
                type: parsed[1],
                datetime
            });
        }
        this.loading = false;
    }

    async download(filename: string) {
        const token = this.currentUser.token;
        const downloadUrl = `${environment.apiUrl}/admin/backup/download/files/${filename}/${token}`;
        window.open(downloadUrl, '_blank');
    }

  delete(filename: string) {
      // Confirm the deletion
      if (confirm('Opravdujete, že chcete smazat zálohu?')) {
          this.API.deleteBackup(filename).then(() => {
              // Refresh the list of backups after deletion
              this.init();
          }).catch((error) => {
              console.error('Error deleting backup:', error);
          });
      }
  }
}
