import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../../_services/api.service';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material';
import {AddBuildingDialog} from '../add-building/add-building.component';
import {AddBuildingImageDialog} from './add-building-image/add-building-image.component';
import {ConfirmDialog} from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-show-building',
  templateUrl: './show-building.component.html',
  styleUrls: ['./show-building.component.css']
})
export class ShowBuildingComponent implements OnInit {

  data: any;
  areas: any;
  loading: boolean;
  saveLoading: boolean;
  id: number;
  formGroup: FormGroup;
  responseMessage: string;


  constructor(private API: ApiService, private route: ActivatedRoute, private formBuilder: FormBuilder, private dialog: MatDialog, private snackBar: MatSnackBar) {

    this.id = this.route.snapshot.params.id;
    this.loading = true;
    this.saveLoading = true;

    this.init().then(() => {

      this.formGroup = this.formBuilder.group({

        name: ['', Validators.required],
        areas: [null],
        building_code: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
        address: [null],
        gps: [null],
        keys: [null],
        note: [null],

      });
      this.formGroup.patchValue(this.data.building);
      if (this.data.building.gps_lat && this.data.building.gps_lon){
        this.formGroup.patchValue({gps: this.data.building.gps_lat + ', ' + this.data.building.gps_lon});
      }
      this.formGroup.patchValue({areas: this.data.areas});


    });

  }

  async init() {

    this.loading = true;
    this.data = await this.API.getBuilding(this.id);
    this.areas = await this.API.getAreas();
    this.loading = false;
    this.saveLoading = false;

  }

  ngOnInit() {

  }

  async sendForm() {

    if (!this.formGroup.valid) {
      return;
    }
    let response: any;
    this.saveLoading = true;
    var buildingData = this.formGroup.value;
    let tmp = buildingData.gps.split(', ');
    delete buildingData.gps;

    buildingData['gps_lat'] = tmp[0];
    buildingData['gps_lon'] = tmp[1];

    response = await this.API.saveBuilding(this.id, buildingData);

    if (response.status === 'ok') {
      this.responseMessage = 'Objekt uložen';
    } else {
      this.responseMessage = response.message;
    }
    this.saveLoading = false;


  }

   getImage(url: string) {

    return this.API.getImage(url);

  }

  async removeImage(id: number, index: number) {

    const dialogRef: MatDialogRef<ConfirmDialog> = this.dialog.open(ConfirmDialog);
    dialogRef.componentInstance.title = 'Upozornění';
    dialogRef.componentInstance.message = 'Opravdu chcete smazat obrázek?';
    dialogRef.componentInstance.confirmText = 'Ano';

    dialogRef.afterClosed().subscribe(async (result) => {

      if (result === true) {

          this.data.photos.splice(index, 1);
          let response: any;
          response = await this.API.removeImage(id);
      }

    });

  }

  addImage() {
    let dialogRef: MatDialogRef<AddBuildingImageDialog>;
    const data = {buildingId: this.id};

    dialogRef = this.dialog.open(AddBuildingImageDialog, {
      width: '25%',
      data
    });

    dialogRef.afterClosed().subscribe(response => {

      if (response !== false) {
        this.init();
      }

    });
  }

    openMap() {
        window.open('https://www.google.com/maps/search/?api=1&query=' + this.formGroup.controls.gps.value, '_blank');
    }

    async delete() {

      const dialogRef: MatDialogRef<ConfirmDialog> = this.dialog.open(ConfirmDialog);
      dialogRef.componentInstance.title = 'Upozornění';
      dialogRef.componentInstance.message = 'Opravdu chcete odstranit objekt?';
      dialogRef.componentInstance.confirmText = 'Ano';

      dialogRef.afterClosed().subscribe(async (result) => {
        if (result === true) {
          const response = await this.API.deleteBuilding(this.id);
          if (response.status === 'ok') {
            this.snackBar.open('✅ Budova byla úspěšně smazána...', 'OK', { duration: 5000 });
            // Redirect to buildings list
            setTimeout(() => {
              window.location.href = '/admin/buildings';
            }, 2000);
          } else {
            this.snackBar.open('⁉️ Něco se pokazilo: ' + response.message, 'OK', { duration: 5000 });
            this.responseMessage = response.message;
          }
        }
      });
    }

}
