import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { fakeBackendProvider } from './_helpers';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {RoutingModule} from './routing.module';
import {SharedModule} from './shared/shared.module';
import {MatButtonModule, MatCardModule, MatFormFieldModule, MatGridListModule, MatInputModule} from '@angular/material';
import {HomeComponent} from './routes/home';
import {LoginComponent} from './routes/login';
import {RegisterComponent} from './routes/register';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import {BuildingsModule} from './routes/buildings/buildings.module';
import {AreasModule} from './routes/areas/areas.module';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import {ConfirmDialog} from './shared/components/confirm-dialog/confirm-dialog.component';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@NgModule({
  declarations: [
      AppComponent,
      HomeComponent,
      LoginComponent,
      RegisterComponent,
      ConfirmDialog
  ],
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatIconModule,
        MatSidenavModule,
        MatListModule,
        RoutingModule,
        SharedModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatGridListModule,
        BuildingsModule,
        MatSortModule,
        MatSnackBarModule,
        AreasModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBiXLuymMKgk8kRW2clzP_SdqA9ywCPlwI',
            libraries: ['drawing']
        }),
        MatProgressSpinnerModule
    ],
  providers: [
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
    entryComponents: [
        ConfirmDialog
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
