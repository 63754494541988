import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {User} from './_models';
import {Router} from '@angular/router';
import {AuthenticationService, ApiService} from './_services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'OSE Security';
  currentUser: User;

  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private router: Router, private authenticationService: AuthenticationService, private api: ApiService, private snackBar: MatSnackBar) {

    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);


    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener

  );
  }

  OnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  isLoggedIn(): boolean{
    if (this.currentUser) {
      return true;
    } else {
      return false;
    }
  }

  async downloadAPK(version: string): Promise<void> {
    const blob = await this.api.downloadApk(version);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `ose-security-${version}.apk`;  // Note the corrected template string
    document.body.appendChild(a); // (optional, but recommended for Firefox compatibility)
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  async createBackup(): Promise<void> {

    let snackBarRef = this.snackBar.open('Záloha se vytváří...', undefined, {
      duration: 0, // keep open until manually closed
    });

    try {
      const response = await this.api.downloadDatabaseBackup();
      // const blob = response.body!;
      // const filename =  `osesecurity_${new Date().toISOString().replace(/[:.]/g, '-')}.sql`;
      //
      // const url = window.URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // a.href = url;
      // a.download = filename;  // set the filename
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);
      // window.URL.revokeObjectURL(url);
      // snackBarRef.dismiss();
      // tslint:disable-next-line:max-line-length
      snackBarRef = this.snackBar.open('✅ Záloha databáze vytvořena, ⏳ Vytváří se záloha souborů, stav můžete sledovat na hlavní stránce...', undefined, { duration: 0 });

    } catch (error) {
      snackBarRef.dismiss();
      // tslint:disable-next-line:max-line-length
      snackBarRef = this.snackBar.open('❌ Záloha databáze se nepovedla, ⏳ Vytváří se záloha souborů, stav můžete sledovat na hlavní stránce...', undefined, { duration: 0 });
      console.error('Backup download failed', error);
    }

    try {
      this.api.downloadFilesBackup().then((response) => {
        console.log(response);
      });
      // snackBarRef = this.snackBar.open('✅ Záloha souborů vytvořena.', undefined, { duration: 5000 });
    } catch (error) {
      snackBarRef.dismiss();
      console.error('Backup files download failed', error);
    }


  }

  // Helper method to parse filename from header
  private getFilenameFromHeader(header: string | null): string | null {
    if (!header) return null;
    const matches = /filename="?([^"]+)"?/.exec(header);
    return matches && matches[1] ? matches[1] : null;
  }


  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
