import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NotfoundComponent} from './components/notfound/notfound.component';
import {MatButtonModule, MatIconModule} from '@angular/material';
import {AlertComponent} from './components/alert';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatIconModule,
    MatButtonModule
  ],
  declarations: [
      NotfoundComponent,
      AlertComponent
  ],
  exports: [
  ],
  entryComponents: [
  ],
})
export class SharedModule {
}
