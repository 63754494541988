import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class ApiService {


    constructor( private http: HttpClient) {
    }

    async getBuildings(offset = 0, filterSort = {}): Promise<any> {
        if (Object.keys(filterSort).length > 0) {

            return this.http.post(environment.apiUrl + '/admin/buildings/list/' + offset, filterSort, this.defaultHttpClientOptions)
                .pipe().toPromise();

        } else {
            return this.http.get(environment.apiUrl + '/admin/buildings/list/' + offset, this.defaultHttpClientOptions)
                .pipe().toPromise();
        }
    }
    async getBuilding(id: number): Promise<any> {
        return this.http.get(environment.apiUrl + '/admin/buildings/detail/' + id, this.defaultHttpClientOptions)
            .pipe().toPromise();
    }

    async addBuilding(data: object): Promise<any> {
        return this.http.post(environment.apiUrl + '/admin/buildings/add', data, this.defaultHttpClientOptions)
            .pipe().toPromise();
    }

    async saveBuilding(id: number, data: object): Promise<any> {
        return this.http.post(environment.apiUrl + '/admin/buildings/detail/' + id, data, this.defaultHttpClientOptions)
            .pipe().toPromise();
    }

    async uploadImage(id, data): Promise<any> {
        return this.http.post(environment.apiUrl + '/admin/buildings/upload', data, {headers: new HttpHeaders({'X-workbox-header': id})})
            .pipe().toPromise();
    }
    async removeImage(id: number): Promise<any> {
        return this.http.get(environment.apiUrl + '/admin/buildings/remove-image/' + id, this.defaultHttpClientOptions)
            .pipe().toPromise();
    }
    getImage(url: string): Observable<any> {
        return this.http.get(url, {responseType: 'blob'}).pipe(map(e => e));
    }
    async archiveBuilding(id: number): Promise<any> {
        return this.http.get(environment.apiUrl + '/admin/buildings/archive/' + id, this.defaultHttpClientOptions)
            .pipe().toPromise();
    }

  async deleteBuilding(id: number): Promise<any> {
    return this.http.get(environment.apiUrl + '/admin/buildings/delete/' + id, this.defaultHttpClientOptions)
      .pipe().toPromise();
  }




    async getBuildingsStats(): Promise<any> {
        return this.http.get(environment.apiUrl + '/admin/buildings/stats', this.defaultHttpClientOptions)
            .pipe().toPromise();
    }

    async getBuildingsMobile(areaId: number): Promise<any> {
        return this.http.get(environment.apiUrl + '/buildings/' + areaId, this.defaultHttpClientOptions)
            .pipe().toPromise();
    }




    async getAreas(): Promise<any> {
        return this.http.get(environment.apiUrl + '/admin/areas', this.defaultHttpClientOptions)
            .pipe().toPromise();
    }
    async getArea(id: number): Promise<any> {
        return this.http.get(environment.apiUrl + '/admin/areas/edit/' + id, this.defaultHttpClientOptions)
            .pipe().toPromise();
    }
    async editArea(id: number, data: object): Promise<any> {
        return this.http.post(environment.apiUrl + '/admin/areas/edit/' + id, data, this.defaultHttpClientOptions)
            .pipe().toPromise();
    }
    async addArea(data: object): Promise<any> {
        return this.http.post(environment.apiUrl + '/admin/areas/add', data, this.defaultHttpClientOptions)
            .pipe().toPromise();
    }

    async deleteArea(id: number): Promise<any> {
        return this.http.get(environment.apiUrl + '/admin/areas/delete/' + id, this.defaultHttpClientOptions)
            .pipe().toPromise();
    }

    async downloadApk(version: string): Promise<any> {
        const response = await this.http.get(environment.apiUrl + '/admin/download/' + version, { responseType: 'blob', observe: 'response',})
        .pipe().toPromise();
        return response.body as Blob;
    }
    async downloadDatabaseBackup(): Promise<any> {
        const response = await this.http.get(environment.apiUrl + '/admin/backup/download/database/', { responseType: 'blob', observe: 'response',})
        .pipe().toPromise();
        return response;
    }
    async downloadFilesBackup(): Promise<any> {
        return this.http.get(environment.apiUrl + '/admin/backup/create/files/', this.defaultHttpClientOptions)
        .pipe().toPromise();
    }

    async getBackupList(): Promise<any> {
        return this.http.get(environment.apiUrl + '/admin/backup/list/', this.defaultHttpClientOptions)
        .pipe().toPromise();
    }

  async deleteBackup(filename: string): Promise<any> {
    return this.http.get(environment.apiUrl + '/admin/backup/delete/' + filename, this.defaultHttpClientOptions)
      .pipe().toPromise();
  }

    async getStats(): Promise<any> {
        return this.http.get(environment.apiUrl + '/admin/stats', this.defaultHttpClientOptions)
            .pipe().toPromise();
    }


    private get defaultHttpClientOptions() {
        return {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    }



}
